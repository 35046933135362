import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3'
import styles from "./radar.module.css"
import InfoIcon from '../images/info.svg'


const Radar = (props) => {
    const {technologies, currentTechnology, currentTechnologyHover, currentType, currentStage, currentPerson, handleSelect, className} = props
    const radius = 300
    const margin = 120
    const stroke = 6
    const types = ['Language/Framework', 'Technique', 'Platform', 'Tool']
    const colors = ['#8bc34a', '#2196f3', '#ffc107', '#f44336']
    
    const stages = ['Adopt', 'Trial', 'Assess', 'Hold']
    const levels = [0.55, 0.70, 0.85, 1.00]
    const angle = Math.PI * 2 / types.length
    const wrap = 100
    const svg = useRef()
    const includedTechnologies = technologies.filter(technology => technology.type !== null && technology.stage !== null)
    const excludedTechnologies = technologies.filter(technology => technology.type === null || technology.stage === null)
    const phi = 1.618
    const circle_radius = 6
    const sectors = types.length

    types.forEach((type, sector) => {
        stages.forEach((stage, crown) => {
            const stageTechnologies = includedTechnologies.filter(technology => technology.type === type && technology.stage === stage)
            const min_crown_radius = (crown? levels[crown - 1] : 0) * radius + circle_radius * 2
            const max_crown_radius = levels[crown] * radius - circle_radius * 2
            const radial_space = max_crown_radius - min_crown_radius

            stageTechnologies.forEach((technology, i) => {
                const theta = ((2 * Math.PI * (i + 1) / (phi ** 2)) % (2 * Math.PI)) / sectors + (2 * Math.PI * sector / sectors) - Math.PI / 2
                const r = Math.sqrt(i + 1) / Math.sqrt(stageTechnologies.length) * radial_space + min_crown_radius
                technology.x = Math.cos(theta) * r
                technology.y = Math.sin(theta) * r
            })
        })
    })

    useEffect(() => {

        const pie = d3.pie()
            .sort(null)
            .value(() => 1 / types.length)

        const arc = d3.arc()
            .innerRadius(radius + stroke / 2)
            .outerRadius(radius + stroke / 2)
            .startAngle(d => d.startAngle)
            .endAngle(d => d.endAngle)
        
        const resizeObserver = new ResizeObserver(([entry]) => {
            if(svg.current) {
                const scale = (radius + margin) * 2 / svg.current.getBoundingClientRect().width
                d3.select(svg.current)
                    .selectAll(`.${styles.label}`)
                    .attr('transform', `scale(${scale} ${scale})`)
                    .attr('y', d => (-radius * d + 12) / scale)
            }
        })

        d3.select(svg.current)
            .attr('class', styles.radar)
        
        const container = d3.select(svg.current)
            .append('g')
            .attr('transform', `translate(${radius + margin},${radius + margin})`)
        
        //Rings
        container.append('g')
            .selectAll('.ring')
            .data(levels)
            .enter()
            .append('g')
            .attr('class', 'ring')
            .attr('fill', 'none')
            .each(function (d, i) {
                const arc = d3.arc().innerRadius((i? levels[i - 1] : 0) * radius).outerRadius(d * radius).startAngle(0).endAngle(Math.PI * 2)
                d3.select(this)
                    .append('path')
                    .attr('d', arc)
            })

        //Technologies
        container.append('g')
            .selectAll(`.${styles.technology}`)
            .data(includedTechnologies)
            .enter()
            .append('circle')
            .attr('class', `${styles.technology} tooltip`)
            .attr('r', circle_radius)
            .attr('data', (d, i) => `${i} ${d.name}`)
            .attr('data-tooltip', d => d.name)
            .attr('fill', d => colors[types.indexOf(d.type)])
            .attr('cx', d => d.x)
            .attr('cy', d => d.y)
            .on('click', function (e) {
                handleSelect(d3.select(this).datum())
            })
        
        const grid = container.append('g')
            .attr('class', styles.grid)

        //Stages
        grid.selectAll(`.${styles.level}`)
            .data(levels)
            .enter()
            .append('circle')
            .attr('class', styles.level)
            .attr('r', d => radius * d)
        
        //Limit
        grid.selectAll(`.${styles.limit}`)
            .data(types)
            .enter()
            .append('line')
            .attr('class', styles.limit)
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', (d, i) => Math.round(radius * Math.cos(angle * i)))
            .attr('y2', (d, i) => Math.round(radius * Math.sin(angle * i)))
        
        //Labels
        grid.selectAll(`.${styles.label}`)
            .data(levels)
            .enter()
            .append('foreignObject')
            .attr('class', styles.label)
            .attr('width', wrap)
            .attr('height', wrap)
            .attr('x', 12)
            .attr('y', d => -radius * d + 12)
            .append('xhtml:div')
            .text((d, i) => stages[i])
        
        //Types
        container.append('g')
            .selectAll('path')
            .data(pie(types))
            .enter()
            .append('path')
            .attr('class', 'arc')
            .attr('stroke', (d, i) => colors[i])
            .attr('stroke-width', stroke)
            .attr('d', arc)
        
        resizeObserver.observe(svg.current.parentNode)

        // eslint-disable-next-line react-hooks/exhaustive-deps            
    }, [])

    useEffect(() => {
        const container = d3.select(svg.current)
        container.selectAll(`.${styles.technology}`)
            .classed(styles.selected, d => currentTechnology && d.id === currentTechnology.id)
    }, [currentTechnology])

    useEffect(() => {

        const container = d3.select(svg.current)
        container.selectAll(`.${styles.technology}`)
            .classed(styles.hover, d => currentTechnologyHover && d.id === currentTechnologyHover.id)
    }, [currentTechnologyHover])

    useEffect(() => {
        const container = d3.select(svg.current)
        container.selectAll(`.arc`)
            .attr('stroke', (d, i) => currentType === undefined || currentType === d.data? colors[i] : '#999')
    }, [currentType, colors])

    useEffect(() => {
        const container = d3.select(svg.current)
        const stageIndex = stages.indexOf(currentStage)
        container.selectAll('.ring path')
            .attr('fill', (d, i) => currentStage === -1 || i !== stageIndex? 'transparent' : 'rgba(0, 0, 0, 0.1)')
    }, [currentStage, stages])

    useEffect(() => {
        const container = d3.select(svg.current)
        container.selectAll(`.${styles.technology}`)
            .attr('fill', d => {
                const showAll = currentType === undefined && currentStage === undefined && currentPerson === undefined
                const matchFilter = d.type === currentType || d.stage === currentStage || d.people.some(person => currentPerson && person?.id === currentPerson?.id)
                const selected = currentTechnology && d.id === currentTechnology.id

                return showAll || matchFilter || selected? colors[types.indexOf(d.type)] : '#999'
            })
    }, [currentType, currentStage, currentPerson, currentTechnology, colors, types])

    return (
        <div className={`${className} ${styles.container}`}>
            {
                excludedTechnologies.length && (
                    <div className={styles.info}>
                        <InfoIcon/>
                        <span>{`${excludedTechnologies.length} technolog${excludedTechnologies.length > 1? 'ies' : 'y'} can't be placed on map for the lack of information (${excludedTechnologies.map(technology => technology.name).join(', ')})`}</span>
                    </div>
                )
            }
            <svg width='100%' viewBox={`0 0 ${(radius + margin) * 2} ${(radius + margin) * 2}`} preserveAspectRatio='xMidYMid meet' ref={svg}/>
            <div className={styles.legends}>
                    {
                        types.map((type, i) => (
                            <div key={type} className={styles.legend}>
                                <div className={styles.ref} style={{backgroundColor: colors[i]}}></div>
                                <div className={styles.label}>{type}</div>
                            </div>
                        ))
                    }
            </div>
        </div>
    )
}

export default Radar